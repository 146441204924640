<template>
  <div>
    <v-card v-if="!isStudent" class="mx-4 mt-4">
      <v-card-text class="px-0">
        <div class="mx-2">
          <v-btn @click="goBack()" text small>
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            <span class="body-2 font-weight-bold">{{ $t("bk.bk_notes") }}</span>
          </v-btn>
        </div>
        <v-divider class="my-4" />
        <v-row class="mx-4">
          <v-col cols="6" sm="4">
            <div class="caption">{{ $t("bk.name") }}</div>
            <div class="font-weight-bold body-2">{{ student.name }}</div>
          </v-col>
          <v-col cols="6" sm="2">
            <div class="d-flex">
              <v-divider
                v-if="$vuetify.breakpoint.smAndUp"
                vertical
                class="mr-3"
              />
              <div>
                <div class="caption">{{ $t("bk.registration_number") }}</div>
                <div class="font-weight-bold body-2">{{ student.nis }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="d-flex">
              <v-divider
                v-if="$vuetify.breakpoint.smAndUp"
                vertical
                class="mr-3"
              />
              <div :class="$vuetify.breakpoint.xsOnly && 'mt-2'">
                <div class="caption">
                  {{
                    appreciation
                      ? $t("bk.table.award_points")
                      : $t("bk.table.violation_points")
                  }}
                </div>
                <div class="font-weight-bold body-2">
                  {{ appreciation ? student.award : student.foul }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="3">
            <div class="d-flex">
              <v-divider
                v-if="$vuetify.breakpoint.smAndUp"
                vertical
                class="mr-3"
              />
              <div :class="$vuetify.breakpoint.xsOnly && 'mt-2'">
                <div class="caption">
                  {{
                    appreciation
                      ? $t("bk.this_month_award")
                      : $t("bk.this_month_violation")
                  }}
                </div>
                <div class="font-weight-bold body-2">
                  {{ appreciation ? student.award_point : student.foul_point }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="mx-4 mt-4">
      <v-tabs>
        <v-tab @click="appreciation = false">
          <v-icon left small>mdi-block-helper</v-icon>
          {{ $t("bk.violant") }}
        </v-tab>
        <v-tab @click="appreciation = true">
          <v-icon left small>mdi-trophy</v-icon>
          {{ $t("bk.award") }}
        </v-tab>
      </v-tabs>

      <v-card class="pa-4">
        <div class="mb-3 d-flex align-center">
          <v-select
            v-model="selectedMonth"
            :disabled="loading"
            :items="$t('absent.month')"
            item-text="value"
            item-value="id"
            outlined
            hide-details
            dense
            class="mr-4 select-150"
            @change="getListDetail"
          />

          <span class="font-weight-bold"
            >{{ $t("bk.total_point") }} : {{ total_point }}</span
          >
        </div>

        <v-data-table
          :headers="tableHeaders"
          :items="dataTable"
          :loading="loading"
          fixed-header
          disable-pagination
          height="500"
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:item.status="{ item }">
            <div>
              {{
                item.status === "publish"
                  ? $t("bk.already_published")
                  : $t("bk.not_yet_published")
              }}
            </div>
          </template>
          <template v-slot:item.created_dt="{ item }">
            <div>
              {{ item.created_dt | dateFormat }}
            </div>
          </template>
          <template v-slot:item.end_date="{ item }">
            <div>
              {{ item.end_date | dateFormat }}
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="loading"
                    icon
                    small
                    class="primary white--text mr-2"
                    @click="detailConseling(item.id)"
                  >
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("bk.detail_counseling_guidance") }}</span>
              </v-tooltip>
              <v-tooltip v-if="checkPrivilages('CGE001')" top>
                <template #activator="{ on }">
                  <v-btn
                    v-on="on"
                    v-if="isUnpublish(item)"
                    :disabled="loading"
                    icon
                    small
                    class="success white--text mr-2"
                    @click="publish(item)"
                  >
                    <v-icon small>mdi-checkbox-marked-circle-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-on="on"
                    v-else
                    :disabled="loading"
                    icon
                    small
                    class="red darken-1 white--text mr-2"
                    @click="publish(item)"
                  >
                    <v-icon small>mdi-cancel</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    isUnpublish(item) ? $t("bk.publish") : $t("bk.unpublish")
                  }}
                </span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    v-on="on"
                    v-if="!isStudent"
                    :disabled="loading"
                    icon
                    small
                    class="red darken-4 white--text"
                    @click="deleteConfirm(item.id)"
                  >
                    <v-icon small>mdi-delete-forever</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("bk.delete_points") }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-dialog v-model="modalview" width="500" persistent>
      <v-card>
        <v-card-title class="title" primary-title>
          {{ $t("bk.detail_counseling_guidance") }}
          <div style="position: absolute; right: 25px;">
            <v-icon @click="modalview = false">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-textarea
                :value="detail.description || '-'"
                :label="$t('bk.notes')"
                disabled
                outlined
                dense
                readonly
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :value="detail.action || '-'"
                :label="$t('app.action')"
                disabled
                class="my-4"
                outlined
                dense
                readonly
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-img :src="detail.file"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" outlined @click="modalview = false">
            {{ $t("app.back") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalConfirm
      :isOpen="modalDelete"
      :close="() => (modalDelete = false)"
      :save="deleteConseling"
      :loading="loadingDelete"
    />
  </div>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");
import {
  getConselingList,
  getConselingById,
  deleteConselingStudent,
  updatePublish
} from "@/api/admin/academic/conseling";
import store from "@/store";
import { mapGetters } from "vuex";
import { checkPrivilages } from "@/utils/privilages";

export default {
  metaInfo: {
    title: i18n.t("routes.bk"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  filters: {
    dateFormat: value => {
      if (!value) return "-";
      return moment(value).format("DD MMM YYYY");
    }
  },
  components: {
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  data() {
    return {
      appreciation: false,
      total_point: 0,
      loading: false,
      modalview: false,
      tableHeaders: [
        {
          text: i18n.t("bk.table.date"),
          value: "created_dt"
        },
        {
          text: i18n.t("bk.table.teacher_name"),
          value: "created_by"
        },
        {
          text: i18n.t("bk.table.point"),
          value: "point"
        },
        {
          text: i18n.t("bk.table.description"),
          value: "description"
        },
        {
          text: i18n.t("bk.table.end_date"),
          value: "end_date"
        },
        {
          text: i18n.t("bk.table.status"),
          value: "status"
        },
        {
          text: i18n.t("app.action"),
          value: "action",
          sortable: false,
          width: 150
        }
      ],
      student: {
        name: "-",
        nis: "-",
        foul: 0,
        foul_point: 0,
        award: 0,
        award_point: 0
      },
      selectedMonth: moment().format("M"),
      dataTable: [],
      detail: {
        id: null,
        description: "",
        action: "",
        file: ""
      },
      loadingDelete: false,
      idStudentDelete: 0,
      modalDelete: false,
      isStudent: store.getters.g_role_type === "STUDENT"
    };
  },
  async created() {
    await this.getListDetail();
  },
  computed: {
    ...mapGetters(["g_user_id"])
  },
  watch: {
    appreciation() {
      this.getListDetail();
    }
  },
  methods: {
    goBack() {
      const query = this.$route.query;
      this.$router.push({
        name: "BK",
        query: { year: query.year, grade: query.grade, class: query.class }
      });
    },
    checkPrivilages: name => checkPrivilages(name),
    isUnpublish(item) {
      return item.status === "unpublish" && !this.isStudent;
    },
    deleteConfirm(id) {
      this.idStudentDelete = id;
      this.modalDelete = true;
    },
    async deleteConseling() {
      this.loadingDelete = true;
      const isAppreciation = this.appreciation
        ? this.$i18n.t("bk.award")
        : this.$i18n.t("bk.violation");
      try {
        const response = await deleteConselingStudent({
          id: this.idStudentDelete
        });
        if (response.data.code) {
          this.snackBar(false, this.$i18n.t("bk.msg.delete") + isAppreciation);
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
        console.error("deleteConseling()", error);
      }
      this.modalDelete = false;
      this.loadingDelete = false;
      this.getListDetail();
    },
    async detailConseling(id) {
      this.loading = true;
      try {
        const response = await getConselingById({ id: id });
        if (response.data.code) {
          this.detail = response.data.data[0];
          this.modalview = true;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
        console.error("detailConseling()", error);
      }
      this.loading = false;
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    async getListDetail() {
      this.loading = true;
      try {
        const person = this.isStudent
          ? this.g_user_id
          : Number(this.$route.query.person);
        const response = await getConselingList({
          person: person,
          type: this.appreciation ? "award" : "foul",
          month: Number(this.selectedMonth)
        });
        if (response.data.code) {
          this.total_point = 0;
          response.data.data[0].detail.map(
            item => (this.total_point += item.point)
          );
          this.dataTable = response.data.data[0].detail;
          this.student = response.data.data[0];
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
        console.error("getListDetail()", error);
      }
      this.loading = false;
    },
    async publish(item) {
      this.loading = true;
      const isPublish = this.isUnpublish(item) ? "publish" : "unpublish";
      try {
        const response = await updatePublish({ id: item.id });
        if (response.data.code) {
          this.snackBar(false, this.$i18n.t(`bk.msg.${isPublish}`));
          this.getListDetail();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.loading = false;
        console.error("publish()", error);
      }
      this.loading = false;
    }
  }
};
</script>
